import { useLabels } from "@/core/context/LabelsContext"
import { ObservableState } from "@/core/form/store/FormStore"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { FeaturedItemsDashboardBlockInput } from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import ProductMultiSelect from "@/experience/components/ProductMultiSelect"
import { ProductRegistrationAvailability } from "@/experience/components/__generated__/ProductMultiSelectQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoFormControl } from "@disco-ui"
import { observer } from "mobx-react-lite"

interface Props {
  form: DashboardBlockFormStore
}

function FeaturedItemsDashboardBlockForm(props: Props) {
  const { form } = props
  const labels = useLabels()
  const dashboard = useDashboardContext()!

  const featuredItems = form.state
    .featuredItems as ObservableState<FeaturedItemsDashboardBlockInput>

  if (!featuredItems) return null

  return renderSelect()

  function renderSelect() {
    const selectedIds = featuredItems.featuredProductIds || []
    const availabilities: ProductRegistrationAvailability[] = ["public"]
    if (!dashboard.isCommunityWelcome) availabilities.push("private")
    let select, label
    switch (featuredItems.featuredKind) {
      case "membership":
        label = "Select the memberships you would like to feature"
        select = (
          <ProductMultiSelect
            values={selectedIds}
            types={["membership_plan"]}
            onChange={handleChangeProduct}
            testid={"FeaturedItemsDashboardBlockForm.membership_plan-select"}
            includeAvailabilities={availabilities}
          />
        )
        break
      case "experience":
      default:
        label = `Select the ${labels.experience.plural} you would like to feature`
        select = (
          <ProductMultiSelect
            values={selectedIds}
            types={["course"]}
            onChange={handleChangeProduct}
            testid={"FeaturedItemsDashboardBlockForm.experience-select"}
            includeAvailabilities={availabilities}
          />
        )
    }
    return (
      <DiscoFormControl
        label={label}
        error={Boolean(form.errorsByField.featuredProductId)}
        errorMessages={form.errorsByField.featuredProductId}
      >
        {select}
      </DiscoFormControl>
    )
  }

  function handleChangeProduct(productIds: GlobalID[]) {
    form.state.featuredItems!.featuredProductIds = productIds
  }
}

export default observer(FeaturedItemsDashboardBlockForm)
