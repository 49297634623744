import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import Relay from "@/relay/relayUtils"
import OrganizationRoleTag from "@/role/OrganizationRoleTag"
import ProductRoleTag from "@/role/ProductRoleTag"
import ProfileAvatarWithDetails from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { Membership } from "./MembersCarousel"
import {
  OrganizationRole,
  ProductRole,
} from "./__generated__/MembersListDashboardBlockQuery.graphql"

interface Props {
  membership: Membership
}

export default function MemberCarouselItem({ membership }: Props) {
  const settingsDrawer = useGlobalDrawer("profileSettings")
  const classes = useStyles()

  return (
    <DiscoContainerButton className={classes.cardContainer} onClick={openProfileDrawer}>
      <ProfileAvatarWithDetails
        avatarSize={96}
        userKey={membership.member}
        classes={{
          detailsContainer: classes.memberDetails,
        }}
        testid={`MembersListDashboardBlock.list.${membership.member.fullName}`}
        details={
          <DiscoText variant={"body-sm"} truncateText={2} align={"center"}>
            {membership.member.bio}
          </DiscoText>
        }
        disableClick
        className={classes.memberCard}
      />

      {Relay.isNodeType(membership, "ProductMembership") ? (
        <ProductRoleTag productRole={membership.role as ProductRole} />
      ) : (
        <OrganizationRoleTag organizationRole={membership.role as OrganizationRole} />
      )}
    </DiscoContainerButton>
  )

  function openProfileDrawer() {
    settingsDrawer.open({
      drawerProfileId: membership.member.id,
      profileSettingsTab: "profile",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1.5),
    border: theme.palette.constants.borderDashboardCard,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(3, 2),
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
  },
  memberDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  memberCard: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
}))
