/**
 * @generated SignedSource<<832443f0fcb7531be85c24bae0b60752>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockCurriculumView = "card" | "list" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CurriculumDashboardBlockFragment$data = {
  readonly curriculumView: DashboardBlockCurriculumView;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDashboardBlockCardViewFragment" | "CurriculumDashboardBlockListViewFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "CurriculumDashboardBlockFragment";
};
export type CurriculumDashboardBlockFragment$key = {
  readonly " $data"?: CurriculumDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurriculumDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurriculumDashboardBlockFragment",
  "selections": [
    {
      "alias": "curriculumView",
      "args": null,
      "kind": "ScalarField",
      "name": "view",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurriculumDashboardBlockCardViewFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurriculumDashboardBlockListViewFragment"
    }
  ],
  "type": "CurriculumDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "e79212cc8c237f988c978c4f5f4a4068";

export default node;
