/**
 * @generated SignedSource<<ef77182904dfba5f9c242f2e85bf23e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OccurrenceStatus = "draft" | "published" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventsDashboardBlockListItem$data = {
  readonly datetimeRange: ReadonlyArray<string>;
  readonly id: string;
  readonly status: OccurrenceStatus;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceNameAndTimeFragment">;
  readonly " $fragmentType": "EventsDashboardBlockListItem";
};
export type EventsDashboardBlockListItem$key = {
  readonly " $data"?: EventsDashboardBlockListItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockListItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventsDashboardBlockListItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceNameAndTimeFragment"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};

(node as any).hash = "252f7dd497bc86dc556bebb47b31c1ca";

export default node;
