import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { DashboardBlockPosition } from "@/dashboard/blocks/__generated__/DashboardBlockItemFragment.graphql"
import { SwiperProps } from "@components/carousel/Swiper"
import { roundDownToNearestOdd } from "@disco-ui/carousel/utils/CarouselUtils"
import { useDashboardContext } from "./DashboardContext"

/**
 * Automatically calculates the optimal DiscoCarousel `breakpoints` or `slidesPerView` prop for a dashboard
 * block, based on that block's position (side/main), whether
 * the dashboard has one or two columns, and the maximum number of items that should
 * be displayed (maxSize argument).
 *
 * Examples:
 * useDashboardBlockCarouselSize(3, { position: "side" })
 *   - returns 1 always
 * useDashboardBlockCarouselSize(5, { position: "main" })
 *   - one column dashboard returns: 5.5 for lg screen+, 3.5 for md-sm, 1 for xs
 *   - two column dashboard returns: 3.5 for lg screen+, 1.5 for md-sm, 1 for xs
 * useDashboardBlockCarouselSize(2, { position: "main" })
 *   - one column dashboard returns: 1.5 for lg screen+, 1.5 for md-sm, 1 for xs
 *   - two column dashboard returns: 1.5 for lg screen+, 1.5 for md-sm, 1 for xs
 */
export function useDashboardBlockCarouselSize(
  maxSize: number,
  block?: {
    position: DashboardBlockPosition
  },
  alignment?: "center" | "left"
): {
  sliceSize: number
  breakpoints?: SwiperProps["config"]["breakpoints"]
  slidesPerView?: SwiperProps["config"]["slidesPerView"]
  centeredSlides?: boolean
} {
  const activeProduct = useActiveProduct()
  const isSide = block?.position === "side"
  const singleColumnDashboard = activeProduct?.dashboard?.layout === "one_column"
  const { isForYou } = useDashboardContext()!

  // if not centered, we can show even or odd number of items
  const leftMax = singleColumnDashboard ? maxSize : maxSize - 1
  // round the max to the nearest odd number (must be odd since we center the carousel active item)
  const centeredMax = roundDownToNearestOdd(singleColumnDashboard ? maxSize : maxSize - 1)
  const alignedMax = alignment === "left" ? leftMax : centeredMax
  // for paginated carousels, it's safe/more efficient to load more than the max size at a time
  const sliceSize = maxSize * 2

  return {
    sliceSize,
    centeredSlides: alignment ? alignment === "center" : true,
    ...(isSide
      ? { slidesPerView: 1 }
      : {
          // add 0.5 for each breakpoint so we can show the carousel hint
          breakpoints: {
            // xs up
            0: {
              slidesPerView: 1,
            },
            // sm up
            600: {
              slidesPerView: Math.max(alignedMax - 2 + 0.5, 1.5),
            },
            // lg up
            1280: {
              slidesPerView: Math.max(alignedMax + 0.5, 1.5),
            },
            // xl up
            1520: {
              slidesPerView: isForYou ? 3.9 : 3.1,
            },
          },
        }),
  }
}
