/**
 * @generated SignedSource<<6b5606eb24b236b3dd44c0fe5727b21e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockFeedView = "card" | "list" | "%future added value";
export type DashboardBlockKind = "banner" | "channels" | "collection_folder" | "community_welcome_hero" | "content" | "continue_your_products" | "curriculum" | "experience_details" | "featured_items" | "feed" | "hero" | "leaderboard" | "members_list" | "memberships" | "pathway_sequence" | "product_admin" | "recently_viewed" | "rich_text" | "upcoming_events" | "welcome_banner" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DashboardBlockItemTemplateFragment$data = {
  readonly dashboardId: string;
  readonly feedView?: DashboardBlockFeedView;
  readonly hideAfter?: string | null;
  readonly id: string;
  readonly kind: DashboardBlockKind;
  readonly ordering: number;
  readonly position: DashboardBlockPosition;
  readonly showOnMobile: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment">;
  readonly " $fragmentType": "DashboardBlockItemTemplateFragment";
};
export type DashboardBlockItemTemplateFragment$key = {
  readonly " $data"?: DashboardBlockItemTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockItemTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardBlockItemTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showOnMobile",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ordering",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dashboardId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideAfter",
          "storageKey": null
        }
      ],
      "type": "WelcomeBannerDashboardBlock",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "feedView",
          "args": null,
          "kind": "ScalarField",
          "name": "view",
          "storageKey": null
        }
      ],
      "type": "FeedDashboardBlock",
      "abstractKey": null
    }
  ],
  "type": "DashboardBlock",
  "abstractKey": "__isDashboardBlock"
};

(node as any).hash = "68a490bc067dece7afee65f8d3fd0557";

export default node;
