/**
 * @generated SignedSource<<4a8c44bdca3060a87f265d435dfc5e39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceInfoToolbarFragment$data = {
  readonly cover: string | null;
  readonly id: string;
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment">;
  } | null;
  readonly showAvailability: boolean;
  readonly showCountdown: boolean;
  readonly showCover: boolean;
  readonly showDates: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment">;
  readonly " $fragmentType": "ExperienceInfoToolbarFragment";
};
export type ExperienceInfoToolbarFragment$key = {
  readonly " $data"?: ExperienceInfoToolbarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceInfoToolbarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceInfoToolbarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCountdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductTypeTagFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    }
  ],
  "type": "BannerDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "386b71258220b4a4c9f832b35da2608c";

export default node;
