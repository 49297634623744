import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import { DashboardBlockPosition } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel } from "@disco-ui"
import MemberCarouselItem from "./MemberCarouselItem"
import { OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment$data } from "./__generated__/OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment.graphql"
import { ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment$data } from "./__generated__/ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment.graphql"

export type Membership =
  | OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment$data["organizationMemberships"]["edges"][0]["node"]
  | ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment$data["productMemberships"]["edges"][0]["node"]

interface Props {
  memberships: Membership[]
  position: DashboardBlockPosition
  totalCount: number
  refetch: {
    hasNext: boolean
    hasPrevious: boolean
    loadMore: () => void
    isLoading: boolean
  }
  title?: React.ReactNode
  moreActions?: React.ReactNode
}

export default function MembersCarousel({
  memberships,
  totalCount,
  refetch,
  position,
  title,
  moreActions,
}: Props) {
  const classes = useStyles()
  const carouselProps = useDashboardBlockCarouselSize(
    4,
    {
      position,
    },
    "center"
  )

  return (
    <DiscoCarousel
      classes={{
        slide: classes.slide,
      }}
      {...carouselProps}
      totalCount={totalCount}
      title={title}
      moreActions={moreActions}
      data={memberships}
      item={(membership) => <MemberCarouselItem membership={membership} />}
      refetch={refetch}
    />
  )
}

const useStyles = makeUseStyles({
  slide: {
    width: "254px",
    height: "270px",
  },
})
