/**
 * @generated SignedSource<<e51902371add3c19f7f6da46d779ab06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeleteDashboardBlockInput = {
  dashboardBlockId: string;
};
export type DeleteDashboardBlockButtonMutation$variables = {
  input: DeleteDashboardBlockInput;
};
export type DeleteDashboardBlockButtonMutation$data = {
  readonly response: {
    readonly deletedBlockId: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type DeleteDashboardBlockButtonMutation = {
  response: DeleteDashboardBlockButtonMutation$data;
  variables: DeleteDashboardBlockButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedBlockId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteDashboardBlockButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteDashboardBlockResponse",
        "kind": "LinkedField",
        "name": "deleteDashboardBlock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteDashboardBlockButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "DeleteDashboardBlockResponse",
        "kind": "LinkedField",
        "name": "deleteDashboardBlock",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "deletedBlockId"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "caeccf90ecaa76c41817658437513fdd",
    "id": null,
    "metadata": {},
    "name": "DeleteDashboardBlockButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteDashboardBlockButtonMutation(\n  $input: DeleteDashboardBlockInput!\n) {\n  response: deleteDashboardBlock(input: $input) {\n    deletedBlockId\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5772fbacce735814c1b9bdab2ef98ec2";

export default node;
