/**
 * @generated SignedSource<<94a4acc89599628e37020715fb0e1450>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductRole = "instructor" | "manager" | "member" | "%future added value";
export type ProductMembersCardViewDashboardBlockQuery$variables = {
  count: number;
  productId: string;
  productRoles?: ReadonlyArray<ProductRole> | null;
};
export type ProductMembersCardViewDashboardBlockQuery$data = {
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment">;
  } | null;
};
export type ProductMembersCardViewDashboardBlockQuery = {
  response: ProductMembersCardViewDashboardBlockQuery$data;
  variables: ProductMembersCardViewDashboardBlockQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productRoles"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "direction": "DESC",
      "field": "last_activity_at"
    }
  },
  {
    "kind": "Variable",
    "name": "roles",
    "variableName": "productRoles"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductMembersCardViewDashboardBlockQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "count",
                    "variableName": "count"
                  },
                  {
                    "kind": "Variable",
                    "name": "productRoles",
                    "variableName": "productRoles"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProductMembersCardViewDashboardBlockQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bio",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfoObjectType",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": [
                  "orderBy",
                  "roles"
                ],
                "handle": "connection",
                "key": "ProductMembersCardViewDashboardBlock__productMemberships",
                "kind": "LinkedHandle",
                "name": "productMemberships"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "feb38d8075e7c5887a3ff8116383bbe4",
    "id": null,
    "metadata": {},
    "name": "ProductMembersCardViewDashboardBlockQuery",
    "operationKind": "query",
    "text": "query ProductMembersCardViewDashboardBlockQuery(\n  $productId: ID!\n  $count: Int!\n  $productRoles: [ProductRole!]\n) {\n  product: node(id: $productId) {\n    __typename\n    ... on Product {\n      ...ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment_ABMFf\n    }\n    id\n  }\n}\n\nfragment ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment_ABMFf on Product {\n  productMemberships(first: $count, orderBy: {field: \"last_activity_at\", direction: DESC}, roles: $productRoles) {\n    totalCount\n    edges {\n      node {\n        __typename\n        id\n        role\n        member {\n          id\n          bio\n          fullName\n          ...ProfileAvatarWithDetailsFragment\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment ProfileAvatarWithDetailsFragment on User {\n  id\n  fullName\n  ...ProfileAvatarFragment\n}\n"
  }
};
})();

(node as any).hash = "2183509646d096cf7f33dd60dfdbc866";

export default node;
