/**
 * @generated SignedSource<<bf4b1b00b4730f23750bf784915f8113>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LeaderboardDashboardBlockCarouselItemFragment$data = {
  readonly fullName: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  readonly " $fragmentType": "LeaderboardDashboardBlockCarouselItemFragment";
};
export type LeaderboardDashboardBlockCarouselItemFragment$key = {
  readonly " $data"?: LeaderboardDashboardBlockCarouselItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LeaderboardDashboardBlockCarouselItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeaderboardDashboardBlockCarouselItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileAvatarFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "7852b5ed778b7bf549752f91d80ad730";

export default node;
