import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import FileDropzone, { IMAGE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import {
  DiscoFormControl,
  DiscoIcon,
  DiscoInput,
  DiscoSection,
  DiscoSelect,
  DiscoSwitch,
  DiscoText,
  DiscoTextButton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoColorPicker from "@disco-ui/color/DiscoColorPicker"
import DiscoTabs from "@disco-ui/tabs/DiscoTabs"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { ASPECT_RATIOS } from "@utils/image/imageConstants"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { DashboardBlockFormStore } from "../blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockHeroHeaderText } from "../edit/__generated__/EditDashboardBlockFormMutation.graphql"
import DashboardBlockShowOnMobileField from "./fields/DashboardBlockShowOnMobileField"

interface Props extends TestIDProps {
  form: DashboardBlockFormStore
}

type HeaderOptionType = {
  title: string
  value: DashboardBlockHeroHeaderText
}

const OPTIONS: HeaderOptionType[] = [
  { value: "default_hello", title: "Hello {Name}" },
  { value: "hello_prefix_wave", title: "👋 Hello {Name}" },
  { value: "hello_suffix_wave", title: "Hello {Name} 👋" },
  { value: "default_welcome", title: "Welcome {Name}" },
  { value: "welcome_prefix_wave", title: "👋 Welcome {Name}" },
  { value: "welcome_suffix_wave", title: "Welcome {Name} 👋" },
  { value: "default_hi", title: "Hi {Name}" },
  { value: "hi_prefix_wave", title: "👋 Hi {Name}" },
  { value: "hi_suffix_wave", title: "Hi {Name} 👋" },
  { value: "hello_prefix_sparkles", title: "✨ Hello {Name}" },
]

function HeroDashboardBlockForm({ testid = "HeroDashboardBlockForm", form }: Props) {
  const { hero } = form.state
  const classes = useStyles()
  const { brainSearch } = useFeatureFlags()

  useEffect(() => {
    if (!hero) return

    // When editing block in image mode color is undefined
    // set default color to avoid empty color picker input
    if (hero.mode === "color" && !hero.color) {
      hero.color = "#F7F0E7"
    }

    // When editing block not in image mode text color is undefined
    // set default text color to avoid empty color picker input
    if (hero.mode === "image" && !hero.textColor) {
      hero.textColor = "#000000"
    }

    // If using no_fill must force a header to avoid empty block
    if (hero.mode === "no_fill" || hero.mode === "color") {
      hero.showWelcomeText = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hero?.mode])

  if (!hero) return null

  const isNotImageMode = hero.mode === "no_fill" || hero.mode === "color"

  return (
    <div className={classes.body}>
      <DiscoSection className={classes.section}>
        <div className={classes.label}>
          <div className={classes.labelLeft}>
            <div className={classes.icon}>
              <DiscoIcon icon={"iconsax.gallery"} width={20} height={20} />
            </div>
            <DiscoText variant={"body-lg-700"}>{"Background"}</DiscoText>
          </div>

          <DiscoTabs
            testid={`${testid}.tabs`}
            tabVariant={"grey-track"}
            routes={[
              {
                label: "Image",
                onClick: () => (hero.mode = "image"),
                active: hero.mode === "image",
                testid: "image",
              },
              {
                label: "Color",
                onClick: () => (hero.mode = "color"),
                active: hero.mode === "color",
                testid: "color",
              },
              {
                label: "No Fill",
                onClick: () => (hero.mode = "no_fill"),
                active: hero.mode === "no_fill",
                testid: "no_fill",
              },
            ]}
          />
        </div>
        {renderBackgroundForm()}
      </DiscoSection>

      <DiscoSection className={classes.section}>
        <div className={classes.label}>
          <div className={classes.labelLeft}>
            <div className={classes.icon}>
              <DiscoIcon icon={"iconsax.text"} width={20} height={20} />
            </div>
            <DiscoText variant={"body-lg-700"}>{"Welcome Text"}</DiscoText>
          </div>

          <DiscoTooltip
            content={"Select image background above to disable text."}
            classes={{
              popper: classes.popper,
            }}
            disabled={!isNotImageMode}
          >
            <div>
              <DiscoSwitch
                checked={hero.showWelcomeText}
                onChange={(value) => (hero.showWelcomeText = value)}
                disabled={isNotImageMode}
                testid={`${testid}.showWelcomeText`}
              />
            </div>
          </DiscoTooltip>
        </div>
        {hero.showWelcomeText && (
          <>
            <DiscoFormControl
              label={
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {"Hero Header"}
                </DiscoText>
              }
            >
              <DiscoSelect
                testid={`${testid}.hero-header`}
                options={OPTIONS}
                value={hero.header}
                onChange={(v) => (hero.header = v)}
                disableClearable
                textFieldInputProps={{
                  endAdornment: (
                    <DiscoIcon
                      icon={"chevron-down"}
                      width={20}
                      height={20}
                      marginRight={1}
                    />
                  ),
                }}
              />
            </DiscoFormControl>

            <DiscoFormControl
              error={Boolean(form.errorsByField.subText)}
              errorMessages={form.errorsByField.subText}
              label={
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {"Hero Subtext"}
                </DiscoText>
              }
              labelRightContent={
                <DiscoText variant={"body-sm"} color={"text.secondary"}>{`${
                  form.state.hero?.subText?.length || 0
                }/100`}</DiscoText>
              }
            >
              <DiscoInput
                data-testid={`${testid}.sub-text`}
                value={hero.subText}
                onChange={(e) => (hero.subText = e.target.value)}
                title={"Hero Subtext"}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </DiscoFormControl>

            {hero.mode === "image" && (
              <DiscoColorPicker
                formControlProps={{
                  label: (
                    <DiscoText variant={"body-sm"} color={"text.secondary"}>
                      {"Text Color"}
                    </DiscoText>
                  ),
                }}
                value={hero.textColor}
                onChange={(e) => (hero.textColor = e)}
              />
            )}
          </>
        )}
      </DiscoSection>

      {brainSearch && (
        <DiscoSection className={classes.section}>
          <div className={classes.label}>
            <div className={classes.labelLeft}>
              <div className={classes.icon}>
                <DiscoIcon icon={"iconsax.message-text"} width={20} height={20} />
              </div>
              <DiscoText variant={"body-lg-700"}>{"Ask AI"}</DiscoText>
            </div>
            <DiscoSwitch checked={hero.askAi} onChange={(v) => (hero.askAi = v)} />
          </div>
        </DiscoSection>
      )}

      <DashboardBlockShowOnMobileField form={form} />
    </div>
  )

  function renderBackgroundForm() {
    if (!hero) return null
    switch (hero.mode) {
      case "no_fill":
        return null
      case "color":
        return (
          <DiscoColorPicker
            formControlProps={{
              label: (
                <DiscoText variant={"body-sm"} color={"text.secondary"}>
                  {"Color"}
                </DiscoText>
              ),
            }}
            value={hero.color}
            onChange={(e) => (hero.color = e)}
          />
        )

      case "image":
        return hero.cover ? (
          <div className={classes.thumbnailContainer}>
            <div className={classes.thumbnailPreview}>
              <CoverPhoto
                coverPhoto={hero.cover}
                testid={`${testid}.ThumbnailField.preview`}
              />
            </div>
            <DiscoTextButton
              onClick={handleRemoveCover}
              className={classes.removeThumbnailButton}
            >
              {"Remove"}
            </DiscoTextButton>
          </div>
        ) : (
          <FileDropzone
            dropzoneOptions={{ accept: IMAGE_FILE_TYPES }}
            message={"Drop or upload an image"}
            testid={`${testid}.ThumbnailField.thumbnail-upload`}
            cropperProps={{
              stencilProps: { aspectRatio: ASPECT_RATIOS.BANNER },
            }}
            onUpload={onUpload}
            onMediaSelect={onMediaSelect}
            allowedFileTypes={["image"]}
            mediaLibraryButtonProps={{ variant: "outlined", color: "grey" }}
            suggestedDimensions={{
              width: 1600,
              height: 400,
            }}
          />
        )
      default:
        return null
    }
  }

  function onUpload(result: MediaResult) {
    if (!hero) return
    hero.cover = result.url
    hero.coverAssetId = result.id
  }

  function onMediaSelect(result: MediaResult) {
    if (!hero) return
    hero.cover = result.url
    hero.coverAssetId = result.id
  }

  function handleRemoveCover() {
    if (!hero) return
    hero.cover = null
    hero.coverAssetId = null
  }
}

const useStyles = makeUseStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  labelLeft: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    backgroundColor: theme.palette.groovy.neutral[100],
    border: "1px solid #E4E4E7",
    padding: theme.spacing(1.25),
    marginRight: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.medium,
    "& svg": {
      color: theme.palette.groovy.neutral[700],
    },
  },
  section: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    boxShadow: theme.palette.groovyDepths.xs,
    border: theme.palette.constants.borderSmall,
  },
  thumbnailPreview: {
    position: "relative",
  },
  thumbnailContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  removeThumbnailButton: {
    alignSelf: "flex-end",
    marginTop: theme.spacing(1.5),
  },
  popper: {
    maxWidth: "160px",
    padding: theme.spacing(1, 1.5),
  },
}))

export default observer(HeroDashboardBlockForm)
