/**
 * @generated SignedSource<<5ce28a6c73a1eef7e3d131b96764bcbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockHeroHeaderText = "default_hello" | "default_hi" | "default_welcome" | "hello_prefix_sparkles" | "hello_prefix_wave" | "hello_suffix_wave" | "hi_prefix_wave" | "hi_suffix_wave" | "welcome_prefix_wave" | "welcome_suffix_wave" | "%future added value";
export type DashboardBlockHeroMode = "color" | "image" | "no_fill" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HeroDashboardBlockFragment$data = {
  readonly askAi: boolean;
  readonly color: string | null;
  readonly cover: string | null;
  readonly header: DashboardBlockHeroHeaderText | null;
  readonly heroMode: DashboardBlockHeroMode;
  readonly id: string;
  readonly showWelcomeText: boolean;
  readonly subText: string | null;
  readonly textColor: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "HeroDashboardBlockFragment";
};
export type HeroDashboardBlockFragment$key = {
  readonly " $data"?: HeroDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showWelcomeText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "askAi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "header",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": "heroMode",
      "args": null,
      "kind": "ScalarField",
      "name": "mode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textColor",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "HeroDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "72904d2c57a188679cda2f077d6bc24d";

export default node;
