/**
 * @generated SignedSource<<a29ccbb52403cc1c7c681238e610ff23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContinueYourProductCardFragment$data = {
  readonly todos: number;
  readonly viewerMembership: {
    readonly " $fragmentSpreads": FragmentRefs<"MemberCurriculumProgressBarFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardFragment">;
  readonly " $fragmentType": "ContinueYourProductCardFragment";
};
export type ContinueYourProductCardFragment$key = {
  readonly " $data"?: ContinueYourProductCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContinueYourProductCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContinueYourProductCardFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductCardFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "todos",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberCurriculumProgressBarFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "c95874a3d7a2442767b8ec095d481f24";

export default node;
