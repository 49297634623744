import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import { ProductCardSkeleton } from "@/product/card/ProductCard"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoCarouselSkeleton, DiscoLink } from "@disco-ui"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import DashboardBlockAdminDropdown from "../../DashboardBlockAdminDropdown"
import CarouselEmptyState from "../CarouselEmptyState"
import ContinueYourProductCard from "./ContinueYourProductCard"
import { ContinueYourProductsDashboardBlockContentPaginationFragment$key } from "./__generated__/ContinueYourProductsDashboardBlockContentPaginationFragment.graphql"
import { ContinueYourProductsDashboardBlockContentQuery } from "./__generated__/ContinueYourProductsDashboardBlockContentQuery.graphql"
import { ContinueYourProductsDashboardBlockFragment$data } from "./__generated__/ContinueYourProductsDashboardBlockFragment.graphql"
import { ContinueYourProductsDashboardBlockPaginationQuery } from "./__generated__/ContinueYourProductsDashboardBlockPaginationQuery.graphql"

interface Props {
  block: ContinueYourProductsDashboardBlockFragment$data
}

function ContinueYourProductsDashboardBlockContent({ block }: Props) {
  const labels = useLabels()
  const activeOrganization = useActiveOrganization()
  const canManage = activeOrganization?.viewerPermissions.has("dashboard.manage")
  const classes = useStyles()
  const { isOneColumn } = useDashboardContext()!

  const { continueYourProductsBlock } =
    useLazyLoadQuery<ContinueYourProductsDashboardBlockContentQuery>(
      graphql`
        query ContinueYourProductsDashboardBlockContentQuery($id: ID!, $first: Int) {
          continueYourProductsBlock: node(id: $id) {
            ... on ContinueYourProductsDashboardBlock {
              title
              position
              ...ContinueYourProductsDashboardBlockContentPaginationFragment
                @arguments(first: $first)
            }
          }
        }
      `,
      {
        id: block.id,
        first: 3,
      }
    )

  const { data, hasNext, hasPrevious, loadNext, isLoadingNext } = usePaginationFragment<
    ContinueYourProductsDashboardBlockPaginationQuery,
    ContinueYourProductsDashboardBlockContentPaginationFragment$key
  >(
    graphql`
      fragment ContinueYourProductsDashboardBlockContentPaginationFragment on ContinueYourProductsDashboardBlock
      @refetchable(queryName: "ContinueYourProductsDashboardBlockPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id
        products(first: $first, after: $after)
          @connection(key: "ContinueYourProductsDashboardBlockContent__products") {
          __id
          totalCount
          edges {
            node {
              id
              slug
              todos
              ...ContinueYourProductCardFragment
            }
          }
        }
      }
    `,
    continueYourProductsBlock
  )

  const todoProducts = Relay.connectionToArray(data?.products)
  if (!todoProducts.length)
    return (
      <CarouselEmptyState
        icon={"3d-box"}
        moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        position={block.position}
        message={
          canManage
            ? `There are (0) ${labels.admin_experience.plural} available.`
            : `You're not registered for any ${labels.experience.plural}.`
        }
        title={continueYourProductsBlock?.title || ""}
        link={
          <DiscoLink
            textVariant={"body-sm-600"}
            to={ROUTE_NAMES.COMMUNITY.EXPERIENCES.ROOT}
          >
            {canManage
              ? `Create New ${labels.admin_experience.singular}`
              : `Explore ${labels.experience.plural}`}
          </DiscoLink>
        }
      />
    )

  return (
    <DiscoCarousel
      testid={"ContinueYourProductsDashboardBlockContent"}
      slidesPerView={isOneColumn ? 1.5 : 1.2}
      centeredSlides={!isOneColumn}
      title={continueYourProductsBlock?.title}
      moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
      classes={{
        slide: classes.slide,
      }}
      refetch={{
        hasNext,
        hasPrevious,
        loadMore: () => loadNext(3),
        isLoading: isLoadingNext,
      }}
      totalCount={data?.products.totalCount || 0}
      data={todoProducts}
      item={(product) => {
        return <ContinueYourProductCard blockId={block.id} productKey={product} />
      }}
    />
  )
}

const useStyles = makeUseStyles({
  slide: {
    maxWidth: "785px",
  },
})

export function ContinueYourProductsDashboardBlockContentSkeleton() {
  return (
    <DiscoCarouselSkeleton
      showTitle
      item={<ProductCardSkeleton coverPosition={"left"} />}
      slidesPerView={3}
    />
  )
}

export default Relay.withSkeleton({
  component: ContinueYourProductsDashboardBlockContent,
  skeleton: ContinueYourProductsDashboardBlockContentSkeleton,
})
