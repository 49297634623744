/**
 * @generated SignedSource<<83372a24d9a7995eb083bf9c04d92bf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockFeaturedItemConfigKind = "collection" | "event" | "experience" | "membership" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
export type DashboardLayout = "one_column" | "two_column" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeaturedItemsDashboardBlockFragment$data = {
  readonly dashboard: {
    readonly layout: DashboardLayout;
  };
  readonly dashboardId: string;
  readonly featuredKind: DashboardBlockFeaturedItemConfigKind;
  readonly id: string;
  readonly position: DashboardBlockPosition;
  readonly showCountdown: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "FeaturedItemsDashboardBlockFragment";
};
export type FeaturedItemsDashboardBlockFragment$key = {
  readonly " $data"?: FeaturedItemsDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedItemsDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeaturedItemsDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dashboardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCountdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "featuredKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "dashboard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "FeaturedItemsDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "ea8da50ccaebd2901f22826947df0190";

export default node;
