/**
 * @generated SignedSource<<6ecf5b5bd29636097e63c3ca34aed214>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PathwaySequenceProductFragment$data = {
  readonly badge: {
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly courseProgressReport: {
    readonly completed: number;
    readonly inProgress: number;
    readonly notStarted: number;
  } | null;
  readonly id: string;
  readonly isMembersAppVisibleToAll: boolean;
  readonly name: string;
  readonly productMemberships: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly member: {
          readonly firstName: string | null;
          readonly id: string;
          readonly lastName: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileListModalListItemFragment">;
        };
      };
    }>;
    readonly totalCount: number;
  };
  readonly slug: string;
  readonly startsAt: string | null;
  readonly type: ProductType;
  readonly viewerCompletedAt: string | null;
  readonly viewerMembership: {
    readonly creationDatetime: string;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"MemberCurriculumProgressBarFragment">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceAdminDropdownFragment">;
  readonly " $fragmentType": "PathwaySequenceProductFragment";
};
export type PathwaySequenceProductFragment$key = {
  readonly " $data"?: PathwaySequenceProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PathwaySequenceProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pathwayProductId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PathwaySequenceProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCompletedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMembersAppVisibleToAll",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationDatetime",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberCurriculumProgressBarFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExperienceAdminDropdownFragment"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        },
        {
          "kind": "Literal",
          "name": "roles",
          "value": [
            "member"
          ]
        }
      ],
      "concreteType": "ProductMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "productMemberships",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProductMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "member",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileAvatarFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileListModalListItemFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "productMemberships(first:3,roles:[\"member\"])"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "pathwayProductId",
          "variableName": "pathwayProductId"
        }
      ],
      "concreteType": "CourseProgressReportResponse",
      "kind": "LinkedField",
      "name": "courseProgressReport",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notStarted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inProgress",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "b3d45dd65b58655a7254dde4ecff7653";

export default node;
