import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import DashboardBlockItemTemplate from "../DashboardBlockItemTemplate"
import ContinueYourProductsDashboardBlockContent from "./ContinueYourProductsDashboardBlockContent"
import { ContinueYourProductsDashboardBlockFragment$key } from "./__generated__/ContinueYourProductsDashboardBlockFragment.graphql"

interface Props {
  dashboardBlockKey: ContinueYourProductsDashboardBlockFragment$key
  index?: number
}

export default function ContinueYourProductsDashboardBlock({
  dashboardBlockKey,
  index,
}: Props) {
  const activeOrganization = useActiveOrganization()
  const block = useFragment<ContinueYourProductsDashboardBlockFragment$key>(
    graphql`
      fragment ContinueYourProductsDashboardBlockFragment on ContinueYourProductsDashboardBlock {
        id
        position
        products {
          totalCount
        }
        ...DashboardBlockItemTemplateFragment
        ...DashboardBlockAdminDropdownFragment
      }
    `,
    dashboardBlockKey
  )

  if (!activeOrganization?.viewerIsOwnerOrAdmin && block.products.totalCount === 0)
    return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <ContinueYourProductsDashboardBlockContent block={block} />
    </DashboardBlockItemTemplate>
  )
}
