import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { useDashboardBlockKindForms } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import {
  DashboardBlockPosition,
  EditDashboardBlockFormFragment$key,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormFragment.graphql"
import {
  EditDashboardBlockFormMutation,
  EditDashboardBlockInput,
} from "@/dashboard/edit/__generated__/EditDashboardBlockFormMutation.graphql"
import DashboardBlockShowOnMobileField from "@/dashboard/form/fields/DashboardBlockShowOnMobileField"
import useCommunityLandingPages from "@/landing-page/util/useCommunityLandingPages"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton } from "@disco-ui"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import React from "react"
import { useFragment } from "react-relay"
import ConnectionHandler from "relay-connection-handler-plus"
import { graphql } from "relay-runtime"

export type EditDashboardBlockFormState = EditDashboardBlockInput & {
  position: DashboardBlockPosition
}

interface Props {
  dashboardBlockKey: EditDashboardBlockFormFragment$key
  onClose: () => void
}

function EditDashboardBlockForm(props: Props) {
  const { dashboardBlockKey, onClose } = props

  const dashboardBlock = useFragment<EditDashboardBlockFormFragment$key>(
    graphql`
      fragment EditDashboardBlockFormFragment on DashboardBlock {
        id
        kind
        position
        showOnMobile
        dashboardId
        __typename
        ... on WelcomeBannerDashboardBlock {
          title
          subtitle
          richEditorDescription
          cover
          hideAfter
        }
        ...RichTextDashboardBlockFormFragment @relay(mask: false)
        ... on ExperienceDetailsDashboardBlock {
          description
          showMembers
          showDates
          showCountdown
          showProgress
          showAttendance
        }
        ... on ContentDashboardBlock {
          contentMode: mode
          contentUsages {
            edges {
              node {
                id
                contentId
              }
            }
          }
        }
        ... on CollectionFolderDashboardBlock {
          contentId
        }
        ... on FeaturedItemsDashboardBlock {
          showCountdown
          featuredKind
          featuredProducts {
            edges {
              node {
                id
              }
            }
          }
        }
        ... on HeroDashboardBlock {
          heroMode: mode
          color
          header
          subText
          cover
          askAi
          showWelcomeText
          textColor
        }
        ... on LeaderboardDashboardBlock {
          leaderboardCount: count
          leaderboardTitle: title
          leaderboardDescription: description
          leaderboardLookbackWindow: lookbackWindow
          leaderboardView: view
        }
        ... on MembersListDashboardBlock {
          membersListCount: count
          memberType
        }
        ... on UpcomingEventsDashboardBlock {
          count
          eventType
          showCover
          eventsView: view
        }
        ... on FeedDashboardBlock {
          feedTitle: title
          feedView: view
        }
        ... on CurriculumDashboardBlock {
          curriculumTitle: title
          curriculumView: view
        }
        ... on ChannelsDashboardBlock {
          channelsTitle: title
        }
        ... on BannerDashboardBlock {
          showCover
          showDates
          showCountdown
          showAvailability
          cover
        }
        ... on CommunityWelcomeHeroDashboardBlock {
          title
          subtitle
          cover
        }
        ... on ContinueYourProductsDashboardBlock {
          title
        }
      }
    `,
    dashboardBlockKey
  )

  const form = useFormStore<EditDashboardBlockFormMutation, EditDashboardBlockFormState>(
    graphql`
      mutation EditDashboardBlockFormMutation($input: EditDashboardBlockInput!) {
        response: editDashboardBlock(input: $input) {
          node {
            ...DashboardBlockItemFragment
            ...EditDashboardBlockFormFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      dashboardBlockId: dashboardBlock.id,
      position: dashboardBlock.position,
      showOnMobile: dashboardBlock.showOnMobile,
      hero:
        dashboardBlock.__typename === "HeroDashboardBlock"
          ? {
              color: dashboardBlock.color,
              header: dashboardBlock.header,
              subText: dashboardBlock.subText,
              mode: dashboardBlock.heroMode!,
              cover: dashboardBlock.cover,
              askAi: dashboardBlock.askAi!,
              showWelcomeText: dashboardBlock.showWelcomeText!,
              textColor: dashboardBlock.textColor,
            }
          : null,
      welcomeBanner:
        dashboardBlock.__typename === "WelcomeBannerDashboardBlock"
          ? {
              title: dashboardBlock.title,
              subtitle: dashboardBlock.subtitle,
              richEditorDescription: dashboardBlock.richEditorDescription,
              cover: dashboardBlock.cover,
              hideAfter: dashboardBlock.hideAfter,
            }
          : null,
      richText:
        dashboardBlock.__typename === "RichTextDashboardBlock"
          ? {
              title: dashboardBlock.richTextBlockTitle,
              ctaText: dashboardBlock.ctaText,
              ctaUrl: dashboardBlock.ctaUrl,
              richEditorContent: dashboardBlock.richEditorContent,
              hasCtaButton: dashboardBlock.hasCtaButton ?? true,
            }
          : null,
      content:
        dashboardBlock.__typename === "ContentDashboardBlock"
          ? {
              mode: dashboardBlock.contentMode!,
              contentIds: observable.array(
                Relay.connectionToArray(dashboardBlock.contentUsages).map(
                  (cu) => cu.contentId
                )
              ),
            }
          : null,
      collectionFolder:
        dashboardBlock.__typename === "CollectionFolderDashboardBlock"
          ? {
              contentId: dashboardBlock.contentId!,
            }
          : null,
      experienceDetails:
        dashboardBlock.__typename === "ExperienceDetailsDashboardBlock"
          ? {
              description: dashboardBlock.description,
              showMembers: dashboardBlock.showMembers,
              showDates: dashboardBlock.showDates,
              showCountdown: dashboardBlock.showCountdown,
              showAttendance: dashboardBlock.showAttendance,
            }
          : null,
      featuredItems:
        dashboardBlock.__typename === "FeaturedItemsDashboardBlock"
          ? {
              featuredProductIds: observable.array(
                Relay.connectionToArray(dashboardBlock.featuredProducts).map(
                  (fp) => fp.id
                )
              ),
              featuredKind: dashboardBlock.featuredKind!,
              showCountdown: dashboardBlock.showCountdown,
            }
          : null,
      upcomingEvents:
        dashboardBlock.__typename === "UpcomingEventsDashboardBlock"
          ? {
              count: dashboardBlock.count,
              eventType: dashboardBlock.eventType,
              showCover: dashboardBlock.showCover,
              view: dashboardBlock.eventsView,
            }
          : null,
      leaderboard:
        dashboardBlock.__typename === "LeaderboardDashboardBlock"
          ? {
              lookbackWindow: dashboardBlock.leaderboardLookbackWindow,
              count: dashboardBlock.leaderboardCount,
              title: dashboardBlock.leaderboardTitle,
              description: dashboardBlock.leaderboardDescription,
              view: dashboardBlock.leaderboardView,
            }
          : null,
      membersList:
        dashboardBlock.__typename === "MembersListDashboardBlock"
          ? {
              count: dashboardBlock.membersListCount,
              memberType: dashboardBlock.memberType,
            }
          : null,
      feed:
        dashboardBlock.__typename === "FeedDashboardBlock"
          ? {
              title: dashboardBlock.feedTitle!,
              view: dashboardBlock.feedView!,
            }
          : null,
      curriculum:
        dashboardBlock.__typename === "CurriculumDashboardBlock"
          ? {
              title: dashboardBlock.curriculumTitle!,
              view: dashboardBlock.curriculumView,
            }
          : null,
      channels:
        dashboardBlock.__typename === "ChannelsDashboardBlock"
          ? {
              title: dashboardBlock.channelsTitle!,
            }
          : null,
      banner:
        dashboardBlock.__typename === "BannerDashboardBlock"
          ? {
              showCover: dashboardBlock.showCover,
              showDates: dashboardBlock.showDates,
              showCountdown: dashboardBlock.showCountdown,
              showAvailability: dashboardBlock.showAvailability,
              cover: dashboardBlock.cover,
            }
          : null,
      communityWelcomeHero:
        dashboardBlock.__typename === "CommunityWelcomeHeroDashboardBlock"
          ? {
              title: dashboardBlock.title,
              subtitle: dashboardBlock.subtitle,
              cover: dashboardBlock.cover,
            }
          : null,
      continueYourProducts:
        dashboardBlock.__typename === "ContinueYourProductsDashboardBlock"
          ? {
              title: dashboardBlock.title!,
            }
          : null,
    }
  )

  const isHeroBlockForm = dashboardBlock.__typename === "HeroDashboardBlock"

  const { completeOrIgnoreChecklistItem } = useOnboardingChecklistContext()
  const { landingPages } = useCommunityLandingPages()
  const kinds = useDashboardBlockKindForms(dashboardBlock.position)
  const kindConfig = kinds[dashboardBlock.kind as keyof typeof kinds]
  if (!kindConfig) return null

  return (
    <Form
      id={"EditDashboardBlockForm"}
      testid={"EditDashboardBlockForm"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            form={form}
            id={"EditDashboardBlockForm"}
            testid={"EditDashboardBlockForm.save-button"}
            disabled={shouldDisableForm()}
          >
            {"Save"}
          </Form.SubmitButton>
        </>
      }
    >
      {React.createElement(kindConfig.form, { form, onClose })}
      {!isHeroBlockForm && <DashboardBlockShowOnMobileField form={form} />}
    </Form>
  )

  function shouldDisableForm() {
    if (!form.isChanged || form.isSubmitting) return true

    if (form.state.content) {
      if (!form.state.content.contentIds?.length) return true
    }

    return false
  }

  async function handleSubmit() {
    const { position: _position, ...formState } = form.state
    const { didSave } = await form.submit(formState, {
      updater: (store) => {
        if (dashboardBlock.__typename === "FeaturedItemsDashboardBlock") {
          const blockRecord = store.get(dashboardBlock.id)
          if (!blockRecord) return

          ConnectionHandler.getConnection(
            blockRecord,
            "FeaturedItemsDashboardBlock__featuredProducts"
          )?.invalidateRecord()
        }
      },
    })

    if (didSave) {
      // Complete checklist item if we're editing one of the community landing pages
      if (landingPages.find((lp) => lp?.dashboard?.id === dashboardBlock.dashboardId)) {
        await completeOrIgnoreChecklistItem("set_up_community_public_page")
      }

      displaySuccessToast({
        message: `${kindConfig?.name} updated`,
        testid: "EditDashboardBlockForm.success-toast",
      })
      onClose()
    }
  }
}

function EditDashboardBlockFormSkeleton() {
  return <div />
}

export default Relay.withSkeleton<Props>({
  component: observer(EditDashboardBlockForm),
  skeleton: EditDashboardBlockFormSkeleton,
})
