/**
 * @generated SignedSource<<5fc99a66804a86ddc819a5a467e9c95d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExperienceDetailsDashboardBlockFragment$data = {
  readonly description: string | null;
  readonly id: string;
  readonly showAttendance: boolean;
  readonly showCountdown: boolean;
  readonly showDates: boolean;
  readonly showMembers: boolean;
  readonly showProgress: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "ExperienceDetailsDashboardBlockFragment";
};
export type ExperienceDetailsDashboardBlockFragment$key = {
  readonly " $data"?: ExperienceDetailsDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExperienceDetailsDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExperienceDetailsDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMembers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showDates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCountdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showAttendance",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "ExperienceDetailsDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "0e0e6e6dfb20c57bddafc9462656179e";

export default node;
