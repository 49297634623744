/**
 * @generated SignedSource<<da7e15dd43fcd8a3a55fe7263be9f11a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockEventsView = "card" | "list" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventsDashboardBlockListViewFragment$data = {
  readonly count: number;
  readonly organizationId: string;
  readonly position: DashboardBlockPosition;
  readonly productId: string | null;
  readonly view: DashboardBlockEventsView;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "EventsDashboardBlockListViewFragment";
};
export type EventsDashboardBlockListViewFragment$key = {
  readonly " $data"?: EventsDashboardBlockListViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockListViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventsDashboardBlockListViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "view",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "UpcomingEventsDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "a3cd81dd5d17cc7322898be4102bfb49";

export default node;
