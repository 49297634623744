/**
 * @generated SignedSource<<6a7581cce47df030b4b5ba663e3b804d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunityWelcomeHeroDashboardBlockFragment$data = {
  readonly cover: string | null;
  readonly organization: {
    readonly plans: {
      readonly totalCount: number;
    };
  };
  readonly subtitle: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "CommunityWelcomeHeroDashboardBlockFragment";
};
export type CommunityWelcomeHeroDashboardBlockFragment$key = {
  readonly " $data"?: CommunityWelcomeHeroDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunityWelcomeHeroDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunityWelcomeHeroDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cover",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": "plans",
          "args": [
            {
              "kind": "Literal",
              "name": "hideDrafts",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "type",
              "value": "membership_plan"
            }
          ],
          "concreteType": "ProductNodeConnection",
          "kind": "LinkedField",
          "name": "products",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": "products(hideDrafts:true,type:\"membership_plan\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CommunityWelcomeHeroDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "4d7a979c14cce7e047e4ecb1d2f6dc53";

export default node;
