import { useLabels } from "@/core/context/LabelsContext"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoText } from "@disco-ui"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import { MembersListDashboardBlockSkeleton } from "../MembersDashboardBlock"
import { MembersDashboardBlockFragment$data } from "../__generated__/MembersDashboardBlockFragment.graphql"
import MembersCarousel from "./MembersCarousel"
import { ProductMembersCardViewDashboardBlockQuery } from "./__generated__/ProductMembersCardViewDashboardBlockQuery.graphql"
import { ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment$key } from "./__generated__/ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment.graphql"
import { ProductMembersCardViewDashboardBlock_productMemberships_PaginationQuery } from "./__generated__/ProductMembersCardViewDashboardBlock_productMemberships_PaginationQuery.graphql"

interface Props {
  block: MembersDashboardBlockFragment$data
  index?: number
}

function ProductMembersCardViewDashboardBlock({ block, index }: Props) {
  const classes = useStyles()

  const labels = useLabels()
  const memberLabel = labels.product_member.plural
  const adminLabel = labels.product_admin.plural

  const { product } = useLazyLoadQuery<ProductMembersCardViewDashboardBlockQuery>(
    graphql`
      query ProductMembersCardViewDashboardBlockQuery(
        $productId: ID!
        $count: Int!
        $productRoles: [ProductRole!]
      ) {
        product: node(id: $productId) {
          ... on Product {
            ...ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment
              @arguments(count: $count, productRoles: $productRoles)
          }
        }
      }
    `,
    {
      productId: block.product?.id || "",
      count: 3,
      productRoles:
        block.memberType === "admin_only"
          ? ["manager", "instructor"]
          : block.memberType === "member_only"
          ? ["member"]
          : undefined,
    }
  )

  const { data, loadNext, hasNext, hasPrevious, isLoadingNext } = usePaginationFragment<
    ProductMembersCardViewDashboardBlock_productMemberships_PaginationQuery,
    ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment$key
  >(
    graphql`
      fragment ProductMembersCardViewDashboardBlock_productMemberships_PaginationFragment on Product
      @refetchable(
        queryName: "ProductMembersCardViewDashboardBlock_productMemberships_PaginationQuery"
      )
      @argumentDefinitions(
        after: { type: "String" }
        count: { type: "Int" }
        productRoles: { type: "[ProductRole!]" }
      ) {
        productMemberships(
          first: $count
          orderBy: { field: "last_activity_at", direction: DESC }
          roles: $productRoles
          after: $after
        ) @connection(key: "ProductMembersCardViewDashboardBlock__productMemberships") {
          totalCount
          edges {
            node {
              __typename
              id
              role
              member {
                id
                bio
                fullName
                ...ProfileAvatarWithDetailsFragment
              }
            }
          }
        }
      }
    `,
    product
  )

  const memberships = Relay.connectionToArray(data?.productMemberships)

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <div className={classes.header}>
        <DiscoText variant={"body-lg-600"} marginLeft={3}>
          {block.memberType === "admin_only" ? adminLabel : memberLabel}
        </DiscoText>

        <DashboardBlockAdminDropdown dashboardBlockKey={block} />
      </div>

      <MembersCarousel
        totalCount={data?.productMemberships.totalCount || 0}
        memberships={memberships}
        position={block.position}
        refetch={{
          hasNext,
          hasPrevious,
          loadMore: () => loadNext(3),
          isLoading: isLoadingNext,
        }}
      />
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

export default Relay.withSkeleton({
  component: ProductMembersCardViewDashboardBlock,
  skeleton: MembersListDashboardBlockSkeleton,
})
