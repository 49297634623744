/**
 * @generated SignedSource<<615c1b81dcc222ca70b653ff47b13f23>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockFeedView = "card" | "list" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedDashboardBlockFragment$data = {
  readonly feedView: DashboardBlockFeedView;
  readonly " $fragmentSpreads": FragmentRefs<"FeedDashboardBlockCardViewFragment" | "FeedDashboardBlockListViewFragment">;
  readonly " $fragmentType": "FeedDashboardBlockFragment";
};
export type FeedDashboardBlockFragment$key = {
  readonly " $data"?: FeedDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedDashboardBlockFragment",
  "selections": [
    {
      "alias": "feedView",
      "args": null,
      "kind": "ScalarField",
      "name": "view",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedDashboardBlockCardViewFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedDashboardBlockListViewFragment"
    }
  ],
  "type": "FeedDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "59a168b32b3eb1963fd19d0716f23994";

export default node;
