/**
 * @generated SignedSource<<ef984177ce71bb4378b8e210448aeec1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RichTextDashboardBlockFormFragment$data = {
  readonly ctaText: string | null;
  readonly ctaUrl: string | null;
  readonly hasCtaButton: boolean | null;
  readonly position: DashboardBlockPosition;
  readonly richEditorContent: string | null;
  readonly richTextBlockTitle: string | null;
  readonly " $fragmentType": "RichTextDashboardBlockFormFragment";
};
export type RichTextDashboardBlockFormFragment$key = {
  readonly " $data"?: RichTextDashboardBlockFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RichTextDashboardBlockFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RichTextDashboardBlockFormFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": "richTextBlockTitle",
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "richEditorContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCtaButton",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaText",
      "storageKey": null
    }
  ],
  "type": "RichTextDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "da831bb79d43f10c0dca276a25989ec8";

export default node;
