/**
 * @generated SignedSource<<3e77ecee1110eb2a6075483aa8e363da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockFeedFilter = "none" | "pinned" | "unpinned" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedDashboardBlockCardViewFragment$data = {
  readonly dashboard: {
    readonly app: {
      readonly feed: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
      } | null;
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"PostCardDashboardFragment" | "usePermissionsFragment">;
  };
  readonly dashboardId: string;
  readonly filter: DashboardBlockFeedFilter;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly viewerCanPostInFeeds: boolean;
  };
  readonly position: DashboardBlockPosition;
  readonly product: {
    readonly id: string;
    readonly viewerCanPostInFeeds: boolean;
  } | null;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "FeedDashboardBlockCardViewFragment";
};
export type FeedDashboardBlockCardViewFragment$key = {
  readonly " $data"?: FeedDashboardBlockCardViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedDashboardBlockCardViewFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerCanPostInFeeds",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedDashboardBlockCardViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filter",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dashboardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "dashboard",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostCardDashboardFragment"
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductApp",
          "kind": "LinkedField",
          "name": "app",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Feed",
              "kind": "LinkedField",
              "name": "feed",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "FeedDashboardBlock",
  "abstractKey": null
};
})();

(node as any).hash = "0aa3a8417fd474889a581e02124ddb80";

export default node;
