import { useLabels } from "@/core/context/LabelsContext"
import ProductCard from "@/product/card/ProductCard"
import MemberCurriculumProgressBar from "@/product/reports/page/curriculum/MemberCurriculumProgressBar"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import useTrackUserActivity from "@/reporting/useTrackUserActivity"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { DiscoIcon, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import pluralize from "pluralize"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { ContinueYourProductCardFragment$key } from "./__generated__/ContinueYourProductCardFragment.graphql"

interface Props {
  blockId: GlobalID
  productKey: ContinueYourProductCardFragment$key
}

export default function ContinueYourProductCard({ blockId, productKey }: Props) {
  const labels = useLabels()
  const theme = useTheme()
  const trackUserActivity = useTrackUserActivity()
  const isMobileView = useIsMobile()
  const isWebView = useIsWebView()
  const isMobile = isMobileView || isWebView

  const product = useFragment<ContinueYourProductCardFragment$key>(
    graphql`
      fragment ContinueYourProductCardFragment on Product {
        ...ProductCardFragment
        todos
        viewerMembership {
          ...MemberCurriculumProgressBarFragment
        }
      }
    `,
    productKey
  )

  const classes = useStyles({ hasTodos: product.todos > 0 })

  return (
    <ProductCard
      productKey={product}
      hideAdminActions
      addShadow
      className={classes.card}
      titleVariant={"body-lg-600"}
      onClick={handleTrackUserActivity}
      linkLocation={"curriculum"}
      coverPosition={isMobile ? "top" : "left"}
      isDashboardBlock
      tagType={"label"}
      hideButton
      footer={
        <div className={classes.footer}>
          {product.todos > 0 && (
            <div className={classes.tag}>
              <DiscoIcon
                icon={"iconsax.lamp-on"}
                color={theme.palette.groovy.neutral[700]}
              />
              <DiscoText
                testid={"ContinueYourProductsDashboardBlock.card-footer"}
                variant={"body-md-600"}
              >{`${product.todos} ${pluralize(
                labels.assignment.singular,
                product.todos
              )} or ${pluralize("Quiz", product.todos)}`}</DiscoText>
            </div>
          )}
          {product.viewerMembership && (
            <MemberCurriculumProgressBar
              memberKey={product.viewerMembership}
              width={"100px"}
            />
          )}
        </div>
      }
    />
  )

  function handleTrackUserActivity() {
    trackUserActivity({
      kind: "clicked",
      entityId: blockId,
    })
  }
}

interface StyleProps {
  hasTodos: boolean
}

const useStyles = makeUseStyles((theme) => ({
  card: {
    minHeight: "130px",
    textAlign: "left",
    [theme.breakpoints.up("lg")]: {
      "& > div": {
        padding: theme.spacing(2),
      },
    },
  },
  footer: ({ hasTodos }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ...styleIf(!hasTodos, {
      justifyContent: "flex-end",
    }),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  tag: {
    backgroundColor: theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.xl,
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    width: "fit-content",
  },
}))
