import { useActiveProduct } from "@/core/context/ActiveProductContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DashboardBlockFeedView } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockFragment.graphql"
import CarouselView from "@assets/images/covers/dashboard-blocks/carousel-view.svg"
import ScrollView from "@assets/images/covers/dashboard-blocks/scroll-view.svg"
import { DiscoFormControl, DiscoInput } from "@disco-ui"
import DiscoRadioWithImage from "@disco-ui/radio/DiscoRadioWithImage"
import { RadioGroup } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { useDashboardContext } from "../util/DashboardContext"

interface Props {
  form: DashboardBlockFormStore
}

function FeedDashboardBlockForm(props: Props) {
  const { form } = props
  const { feed } = form.state
  const classes = useStyles()
  const activeProduct = useActiveProduct()
  const { isForYou } = useDashboardContext()!

  if (!feed) return null

  return (
    <>
      <DiscoFormControl label={"Title"} errorMessages={form.errorsByField.title}>
        <DiscoInput
          value={feed.title}
          onChange={(e) => (feed.title = e.target.value)}
          fullWidth
        />
      </DiscoFormControl>

      {!isForYou && !activeProduct && (
        <DiscoFormControl>
          <DiscoFormControl label={"Feed style"}>
            <RadioGroup
              value={feed.view}
              onChange={(e) => (feed.view = e.target.value as DashboardBlockFeedView)}
              className={classes.radioGroup}
            >
              <DiscoRadioWithImage
                testid={`FeedDashboardBlockForm.radio.card`}
                className={classes.radioButton}
                label={"Carousel"}
                sublabel={<CarouselView />}
                value={"card"}
                checked={feed.view === "card"}
              />

              <DiscoRadioWithImage
                testid={`FeedDashboardBlockForm.radio.list`}
                className={classes.radioButton}
                label={"Scrollable"}
                sublabel={<ScrollView />}
                value={"list"}
                checked={feed.view === "list"}
              />
            </RadioGroup>
          </DiscoFormControl>
        </DiscoFormControl>
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  radioGroup: {
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "column",
    flex: "1 1 auto",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  radioButton: {
    flexGrow: 1,
  },
}))

export default observer(FeedDashboardBlockForm)
