import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { DashboardBlockListQuery$data } from "@/dashboard/__generated__/DashboardBlockListQuery.graphql"
import useCanEditDashboard from "@/dashboard/util/useCanEditDashboard"
import { useMediaQuery } from "@material-ui/core"
import React, { createContext, useContext } from "react"

export const DASHBOARD_ONE_COLUMN_BREAKPOINT = "@media (max-width: 1150px)"

type Dashboard = DashboardBlockListQuery$data["node"] & { __typename: "Dashboard" }

export type DashboardContextValue = Dashboard & {
  isCommunityWelcome: boolean
  isPathway: boolean
  canEdit: boolean
  isOneColumn: boolean
  isForYou: boolean
}

const DashboardContext = createContext<DashboardContextValue | null>(null)

export function useDashboardContext() {
  return useContext(DashboardContext)
}

type DashboardProviderProps = {
  dashboard: Dashboard
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  dashboard,
  children,
}) => {
  const canEdit = useCanEditDashboard(dashboard)
  const isUnderOneColumnBreakpoint = useMediaQuery(DASHBOARD_ONE_COLUMN_BREAKPOINT)
  const activeProduct = useActiveProduct()

  return (
    <DashboardContext.Provider
      value={{
        ...dashboard,
        isCommunityWelcome: Boolean(dashboard.landingPageId),
        isPathway: Boolean(activeProduct?.type === "pathway"),
        canEdit,
        isOneColumn: dashboard.layout === "one_column" || isUnderOneColumnBreakpoint,
        isForYou: dashboard.forYou,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}
