/**
 * @generated SignedSource<<d4179e7de8096ae45e252b73c92682be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockFeedFilter = "none" | "pinned" | "unpinned" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedDashboardBlockListViewFragment$data = {
  readonly dashboard: {
    readonly app: {
      readonly feed: {
        readonly id: string;
        readonly isInactive: boolean;
        readonly " $fragmentSpreads": FragmentRefs<"AIFeedEmptyStateFragment" | "usePermissionsFragment">;
      } | null;
      readonly id: string;
    } | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"PostCardDashboardFragment" | "usePermissionsFragment">;
  };
  readonly filter: DashboardBlockFeedFilter;
  readonly id: string;
  readonly organization: {
    readonly viewerCanPostInFeeds: boolean;
  };
  readonly product: {
    readonly viewerCanPostInFeeds: boolean;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "FeedDashboardBlockListViewFragment";
};
export type FeedDashboardBlockListViewFragment$key = {
  readonly " $data"?: FeedDashboardBlockListViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedDashboardBlockListViewFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "viewerCanPostInFeeds",
    "storageKey": null
  }
],
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "usePermissionsFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedDashboardBlockListViewFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filter",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dashboard",
      "kind": "LinkedField",
      "name": "dashboard",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostCardDashboardFragment"
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductApp",
          "kind": "LinkedField",
          "name": "app",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Feed",
              "kind": "LinkedField",
              "name": "feed",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isInactive",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AIFeedEmptyStateFragment"
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "FeedDashboardBlock",
  "abstractKey": null
};
})();

(node as any).hash = "29357d23fc7f72908f436f4461b9f454";

export default node;
