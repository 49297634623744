import PinnedPostCard from "@/content/post/card/PinnedPostCard"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoEmptyState, DiscoIcon, DiscoSection, DiscoText } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { FragmentRefs } from "relay-runtime"
import DashboardBlockItemTemplate from "../DashboardBlockItemTemplate"
import { FeedDashboardBlockCardViewFragment$data } from "../__generated__/FeedDashboardBlockCardViewFragment.graphql"

interface Props {
  pagination: {
    hasNext: boolean
    isLoading: boolean
    loadMore: () => void
  }
  posts: {
    readonly id: string
    readonly " $fragmentSpreads": FragmentRefs<
      "PostCardFragment" | "PinnedPostCardFragment"
    >
  }[]
  block: FeedDashboardBlockCardViewFragment$data
  index?: number
}

export default function PinnedPostsCard({ block, posts, index, pagination }: Props) {
  const classes = useStyles()

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoSection className={classes.pinnedPosts}>
        <div className={classes.pinnedPostsHeader}>
          <div className={classes.pinnedPostHeaderText}>
            <DiscoIcon icon={"iconsax.custom-pin"} width={16} height={16} active />
            <DiscoText variant={"body-md-600"}>{"Pinned Posts"}</DiscoText>
          </div>
        </div>
        {posts.length ? (
          <div className={classes.pinnedPostsContent}>
            {posts.map((post, cardIndex) => (
              <PinnedPostCard key={post.id} index={cardIndex} postKey={post} />
            ))}

            {pagination.hasNext && (
              <DiscoScrolledIntoView
                onScrolledIntoView={pagination.loadMore}
                isLoading={pagination.isLoading}
              />
            )}
          </div>
        ) : (
          <DiscoEmptyState
            testid={"PinnedPostsCard.empty-state"}
            title={"No Pinned Posts"}
            subtitle={"Pin a post for it to appear here."}
            customSubtitleVariant={"body-md"}
            variant={"compact"}
            className={classes.emptyState}
          />
        )}
      </DiscoSection>
    </DashboardBlockItemTemplate>
  )
}

const useStyles = makeUseStyles((theme) => ({
  pinnedPostsHeader: {
    borderBottom: theme.palette.constants.borderDashboardCard,
  },
  pinnedPostHeaderText: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(2, 2.5),
  },
  pinnedPosts: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    border: theme.palette.constants.borderDashboardCard,
    padding: 0,
  },
  pinnedPostsContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
    maxHeight: "80vh",

    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    [theme.breakpoints.down("md")]: {
      maxHeight: "500px",
    },
  },
  emptyState: {
    backgroundColor: theme.palette.groovy.neutral[200],
    margin: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.big,
    width: "auto",
    padding: theme.spacing(5, 1.5),
  },
}))
