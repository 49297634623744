/**
 * @generated SignedSource<<c5b35be06feca240735e7a32eb877f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventsDashboardBlockCardViewFragment$data = {
  readonly organizationId: string;
  readonly position: DashboardBlockPosition;
  readonly productId: string | null;
  readonly showCover: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "EventsDashboardBlockCardViewFragment";
};
export type EventsDashboardBlockCardViewFragment$key = {
  readonly " $data"?: EventsDashboardBlockCardViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventsDashboardBlockCardViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventsDashboardBlockCardViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showCover",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "UpcomingEventsDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "03ada9d6fd1b0d803b20b6d3dd31ba17";

export default node;
