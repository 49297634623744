/**
 * @generated SignedSource<<12f90f68c0ef52145b637cc5f4823b1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockMembersListMemberType = "admin_only" | "all" | "member_only" | "%future added value";
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MembersDashboardBlockFragment$data = {
  readonly id: string;
  readonly memberCount: number;
  readonly memberType: DashboardBlockMembersListMemberType;
  readonly organizationId: string;
  readonly position: DashboardBlockPosition;
  readonly product: {
    readonly id: string;
    readonly slug: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "MembersDashboardBlockFragment";
};
export type MembersDashboardBlockFragment$key = {
  readonly " $data"?: MembersDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MembersDashboardBlockFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MembersDashboardBlockFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memberType",
      "storageKey": null
    },
    {
      "alias": "memberCount",
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    }
  ],
  "type": "MembersListDashboardBlock",
  "abstractKey": null
};
})();

(node as any).hash = "f2087c2b9b9685a28f081e3fdc1fa12d";

export default node;
