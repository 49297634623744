/**
 * @generated SignedSource<<9c6723f35bc69dcb2e291428b85d64fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedDashboardBlockFragment$data = {
  readonly id: string;
  readonly position: DashboardBlockPosition;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment" | "DashboardBlockItemTemplateFragment">;
  readonly " $fragmentType": "RecentlyViewedDashboardBlockFragment";
};
export type RecentlyViewedDashboardBlockFragment$key = {
  readonly " $data"?: RecentlyViewedDashboardBlockFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedDashboardBlockFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RecentlyViewedDashboardBlockFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockItemTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    }
  ],
  "type": "RecentlyViewedDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "95af4136d05736e2f8c16768d063fefd";

export default node;
