import ContentUsageGridItem, {
  ContentUsageGridItemSkeleton,
} from "@/content-usage/modules/grid/ContentUsageGridItem"
import { ContentUsageGridItemFragment$key } from "@/content-usage/modules/grid/__generated__/ContentUsageGridItemFragment.graphql"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import { useDashboardBlockCarouselSize } from "@/dashboard/util/useDashboardBlockCarouselSize"
import OccurrenceListItem from "@/product/course/event/list/item/OccurrenceListItem"
import { OccurrenceListItemFragment$key } from "@/product/course/event/list/item/__generated__/OccurrenceListItemFragment.graphql"
import Relay from "@/relay/relayUtils"
import useTrackUserActivity from "@/reporting/useTrackUserActivity"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoCarousel, DiscoCarouselSkeleton } from "@disco-ui"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import DashboardBlockAdminDropdown from "../../DashboardBlockAdminDropdown"
import CarouselEmptyState from "../CarouselEmptyState"
import {
  RecentlyViewedDashboardBlockContentPaginationFragment$data,
  RecentlyViewedDashboardBlockContentPaginationFragment$key,
} from "./__generated__/RecentlyViewedDashboardBlockContentPaginationFragment.graphql"
import { RecentlyViewedDashboardBlockContentPaginationQuery } from "./__generated__/RecentlyViewedDashboardBlockContentPaginationQuery.graphql"
import { RecentlyViewedDashboardBlockContentQuery } from "./__generated__/RecentlyViewedDashboardBlockContentQuery.graphql"
import { RecentlyViewedDashboardBlockFragment$data } from "./__generated__/RecentlyViewedDashboardBlockFragment.graphql"

type CarouselNode =
  | RecentlyViewedDashboardBlockContentPaginationFragment$data["recentlyViewedContentUsages"]["edges"][0]["node"]
  | RecentlyViewedDashboardBlockContentPaginationFragment$data["recentlyViewedOccurrences"]["edges"][0]["node"]

interface Props {
  block: RecentlyViewedDashboardBlockFragment$data
}

function RecentlyViewedDashboardBlockContent({ block }: Props) {
  const classes = useStyles()
  const trackUserActivity = useTrackUserActivity()

  const { recentlyViewedBlock } =
    useLazyLoadQuery<RecentlyViewedDashboardBlockContentQuery>(
      graphql`
        query RecentlyViewedDashboardBlockContentQuery($id: ID!, $first: Int) {
          recentlyViewedBlock: node(id: $id) {
            ... on RecentlyViewedDashboardBlock {
              id
              position
              ...RecentlyViewedDashboardBlockContentPaginationFragment
                @arguments(first: $first)
            }
          }
        }
      `,
      {
        id: block.id,
        first: 3,
      },
      {
        fetchPolicy: "network-only",
      }
    )

  const { data, loadNext, hasNext, hasPrevious, isLoadingNext } = usePaginationFragment<
    RecentlyViewedDashboardBlockContentPaginationQuery,
    RecentlyViewedDashboardBlockContentPaginationFragment$key
  >(
    graphql`
      fragment RecentlyViewedDashboardBlockContentPaginationFragment on RecentlyViewedDashboardBlock
      @refetchable(queryName: "RecentlyViewedDashboardBlockContentPaginationQuery")
      @argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
        id
        recentlyViewedContentUsages(first: $first, after: $after)
          @connection(
            key: "RecentlyViewedDashboardBlockContent__recentlyViewedContentUsages"
          ) {
          __id
          totalCount
          edges {
            node {
              __typename
              id
              product {
                slug
              }
              ...ContentUsageGridItemFragment
            }
          }
        }
        recentlyViewedOccurrences(first: $first, after: $after) {
          __id
          totalCount
          edges {
            node {
              __typename
              id

              product {
                slug
              }

              ...OccurrenceListItemFragment
            }
          }
        }
      }
    `,
    recentlyViewedBlock
  )

  // Alternate the order of recently viewed content usages and event recordings
  const recentlyViewedContentUsages = Relay.connectionToArray(
    data?.recentlyViewedContentUsages
  )
  const recentlyViewedOccurrences = Relay.connectionToArray(
    data?.recentlyViewedOccurrences
  )

  // Alternate content usage and event recording cards
  const recentlyViewed: CarouselNode[] = []
  const l = Math.min(recentlyViewedContentUsages.length, recentlyViewedOccurrences.length)

  for (let i = 0; i < l; i++) {
    recentlyViewed.push(recentlyViewedContentUsages[i], recentlyViewedOccurrences[i])
  }

  recentlyViewed.push(
    ...recentlyViewedContentUsages.slice(l),
    ...recentlyViewedOccurrences.slice(l)
  )

  const carouselProps = useDashboardBlockCarouselSize(4, block, "center")

  if (!recentlyViewed.length)
    return (
      <CarouselEmptyState
        icon={"file"}
        message={"You have not viewed any content recently."}
        moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        title={"Recently Viewed"}
        cardCount={4}
        variant={"recently_viewed"}
        position={block.position}
      />
    )

  const totalCount =
    data!.recentlyViewedOccurrences.totalCount +
    data!.recentlyViewedContentUsages.totalCount

  return (
    <DiscoCarousel
      title={"Recently Viewed"}
      {...carouselProps}
      data={recentlyViewed}
      classes={{ slide: classes.item }}
      item={(item, idx) => {
        if (Relay.isNodeType(item, "ContentUsage")) {
          return (
            <ContentUsageGridItem
              key={item.id}
              isDashboardBlock
              contentUsageKey={item as ContentUsageGridItemFragment$key}
              testid={`RecentlyViewedDashboardBlockContent.item-${idx}`}
              hideMedia
              showOverflow={false}
              navigateOnClick
              onClick={handleTrackUserActivity}
              showLocation
              hideFooter
            />
          )
        } else if (Relay.isNodeType(item, "Occurrence")) {
          return (
            <OccurrenceListItem
              testid={`RecentlyViewedDashboardBlockContent.card-${idx}`}
              variant={"card"}
              occurrenceKey={item as OccurrenceListItemFragment$key}
              navigateOnClick
              hideCover
              hideHosts
              onClick={handleTrackUserActivity}
            />
          )
        }
        return <></>
      }}
      totalCount={totalCount}
      moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
      refetch={{
        hasNext,
        hasPrevious,
        loadMore: () => loadNext(6),
        isLoading: isLoadingNext,
      }}
    />
  )

  function handleTrackUserActivity() {
    trackUserActivity({
      kind: "clicked",
      entityId: block.id,
    })
  }
}

const useStyles = makeUseStyles({
  item: {
    width: "254px",
    maxHeight: "180px",
  },
})

function RecentlyViewedDashboardBlockContentSkeleton() {
  const { isOneColumn } = useDashboardContext()!
  return (
    <DiscoCarouselSkeleton
      showTitle
      item={<ContentUsageGridItemSkeleton height={"130px"} />}
      slidesPerView={isOneColumn ? 4 : 3}
    />
  )
}

export default Relay.withSkeleton({
  component: RecentlyViewedDashboardBlockContent,
  skeleton: RecentlyViewedDashboardBlockContentSkeleton,
})
