/**
 * @generated SignedSource<<ea0bf4bb636d5861d5977a09db4229a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DashboardBlockPosition = "main" | "side" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CollectionFolderDashboardBlockContentFragment$data = {
  readonly contentId: string;
  readonly position: DashboardBlockPosition;
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment">;
  readonly " $fragmentType": "CollectionFolderDashboardBlockContentFragment";
};
export type CollectionFolderDashboardBlockContentFragment$key = {
  readonly " $data"?: CollectionFolderDashboardBlockContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionFolderDashboardBlockContentFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionFolderDashboardBlockContentFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    }
  ],
  "type": "CollectionFolderDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "bae3745c294b8f98c56313aca98196c7";

export default node;
