/**
 * @generated SignedSource<<3e9ce9d1ffebd4fa0337481c34ff4f2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentDashboardBlockAdminDropdownFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardBlockAdminDropdownFragment">;
  readonly " $fragmentType": "ContentDashboardBlockAdminDropdownFragment";
};
export type ContentDashboardBlockAdminDropdownFragment$key = {
  readonly " $data"?: ContentDashboardBlockAdminDropdownFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentDashboardBlockAdminDropdownFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentDashboardBlockAdminDropdownFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DashboardBlockAdminDropdownFragment"
    }
  ],
  "type": "ContentDashboardBlock",
  "abstractKey": null
};

(node as any).hash = "d68986ddf54a4504fc98af0b2446c669";

export default node;
