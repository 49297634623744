import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabels } from "@/core/context/LabelsContext"
import { DashboardBlockFormStore } from "@/dashboard/blocks/kinds/DashboardBlockKindForms"
import { DiscoFormControl, DiscoSelect } from "@disco-ui"
import DiscoNumberInput from "@disco-ui/input/DiscoNumberInput"
import { observer } from "mobx-react-lite"
import { useDashboardContext } from "../util/DashboardContext"

interface Props {
  form: DashboardBlockFormStore
}

function MembersListDashboardBlockForm(props: Props) {
  const { form } = props
  const { isForYou } = useDashboardContext()!

  const activeProduct = useActiveProduct()

  const labels = useLabels()
  const memberLabel = labels.admin_member.plural
  const adminLabel = activeProduct ? labels.product_admin.plural : "Admins"

  const { membersList } = form.state
  if (!membersList) return null

  return (
    <>
      {!activeProduct && !isForYou && (
        <DiscoFormControl
          label={`How many ${memberLabel} do you wish to show on this block?`}
        >
          <DiscoNumberInput
            placeholder={"4"}
            value={membersList.count}
            onChange={(e) =>
              (membersList.count = e.target.value ? parseInt(e.target.value) : null)
            }
          />
        </DiscoFormControl>
      )}
      <DiscoFormControl
        label={`Which group of ${memberLabel} do you wish to show on this block?`}
      >
        <DiscoSelect
          value={membersList.memberType}
          onChange={(v) => (membersList.memberType = v!)}
          options={[
            { value: "admin_only", title: `${adminLabel} only` },
            { value: "member_only", title: `${memberLabel} only` },
            { value: "all", title: `Both ${adminLabel} and ${memberLabel}` },
          ]}
          disableClearable
        />
      </DiscoFormControl>
    </>
  )
}

export default observer(MembersListDashboardBlockForm)
