import { useLabels } from "@/core/context/LabelsContext"
import Relay from "@/relay/relayUtils"
import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay"
import DashboardBlockAdminDropdown from "../../DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "../DashboardBlockItemTemplate"
import { MembersListDashboardBlockSkeleton } from "../MembersDashboardBlock"
import { MembersDashboardBlockFragment$data } from "../__generated__/MembersDashboardBlockFragment.graphql"
import MembersCarousel from "./MembersCarousel"
import { OrganizationMembersCardViewDashboardBlockQuery } from "./__generated__/OrganizationMembersCardViewDashboardBlockQuery.graphql"
import { OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment$key } from "./__generated__/OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment.graphql"
import { OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationQuery } from "./__generated__/OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationQuery.graphql"

interface Props {
  block: MembersDashboardBlockFragment$data
  index?: number
}

function OrganizationMembersCardViewDashboardBlock({ block, index }: Props) {
  const labels = useLabels()
  const memberLabel = labels.organization_member.plural
  const adminLabel = "Admins"

  const { organization } =
    useLazyLoadQuery<OrganizationMembersCardViewDashboardBlockQuery>(
      graphql`
        query OrganizationMembersCardViewDashboardBlockQuery(
          $organizationId: ID!
          $count: Int!
          $organizationRoles: [OrganizationRole!]
        ) {
          organization: node(id: $organizationId) {
            ... on Organization {
              ...OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment
                @arguments(count: $count, organizationRoles: $organizationRoles)
            }
          }
        }
      `,
      {
        organizationId: block.organizationId,
        count: 3,
        organizationRoles:
          block.memberType === "admin_only"
            ? ["owner", "admin"]
            : block.memberType === "member_only"
            ? ["member"]
            : undefined,
      }
    )

  const { data, loadNext, hasNext, hasPrevious, isLoadingNext } = usePaginationFragment<
    OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationQuery,
    OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment$key
  >(
    graphql`
      fragment OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationFragment on Organization
      @refetchable(
        queryName: "OrganizationMembersCardViewDashboardBlock_organizationMembershios_PaginationQuery"
      )
      @argumentDefinitions(
        after: { type: "String" }
        count: { type: "Int" }
        organizationRoles: { type: "[OrganizationRole!]" }
      ) {
        organizationMemberships(
          first: $count
          orderBy: members_list
          roles: $organizationRoles
          after: $after
        ) @connection(key: "MembersListDashboardBlock__organizationMemberships") {
          totalCount
          edges {
            node {
              __typename
              id
              role
              member {
                id
                bio
                fullName
                ...ProfileAvatarWithDetailsFragment
              }
            }
          }
        }
      }
    `,
    organization
  )

  const memberships = Relay.connectionToArray(data?.organizationMemberships)

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <MembersCarousel
        title={block.memberType === "admin_only" ? adminLabel : memberLabel}
        position={block.position}
        moreActions={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        totalCount={data?.organizationMemberships.totalCount || 0}
        memberships={memberships}
        refetch={{
          hasNext,
          hasPrevious,
          loadMore: () => loadNext(4),
          isLoading: isLoadingNext,
        }}
      />
    </DashboardBlockItemTemplate>
  )
}

export default Relay.withSkeleton({
  component: OrganizationMembersCardViewDashboardBlock,
  skeleton: MembersListDashboardBlockSkeleton,
})
