import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoText } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useTheme } from "@material-ui/core"

interface Props {
  isOpen: boolean
  onClose: () => void
  publishDashboard: () => void
}

export default function PublishForYouDashboardModal({
  isOpen,
  onClose,
  publishDashboard,
}: Props) {
  const classes = useStyles()
  const theme = useTheme()

  const iconColor = theme.palette.type === "dark" ? theme.palette.common.white : undefined

  return (
    <DiscoWarningModal
      testid={"ForYouDashboard.migration-confirm.DiscoWarningModal"}
      modalContentLabel={"confirm dashboard migration"}
      variant={"primary"}
      onClose={onClose}
      hideIcon
      isOpen={isOpen}
      title={'Publish "For You"'}
      alignTitle={"left"}
      description={
        <div className={classes.body}>
          <div className={classes.transition}>
            <div className={classes.sidebarItem}>
              <DiscoIcon icon={"iconsax.home"} color={iconColor} />
              <DiscoText
                variant={"body-md-600"}
                color={
                  theme.palette.type === "dark" ? "common.white" : "groovy.neutral.500"
                }
              >
                {"Home"}
              </DiscoText>
            </div>

            <DiscoIcon icon={"arrow-stem-right"} color={iconColor} />

            <div className={classes.sidebarItem}>
              <DiscoIcon icon={"stars"} color={iconColor} />
              <DiscoText
                variant={"body-md-600"}
                color={
                  theme.palette.type === "dark" ? "common.white" : "groovy.neutral.500"
                }
              >
                {"For You"}
              </DiscoText>
            </div>
          </div>
          <DiscoText align={"left"}>
            <DiscoText color={"error.main"} variant={"body-md-600-uppercase"}>
              {"Warning: "}
            </DiscoText>
            {
              'Publishing the "For You" page will permanently replace the current community "Home".'
            }
            <DiscoText>
              {' The "For You" page will become the new community home page.'}

              <DiscoText variant={"body-md-700"} component={"span"}>
                {" This action cannot be undone."}
              </DiscoText>
            </DiscoText>
          </DiscoText>
        </div>
      }
      confirmationButtonProps={{
        onClick: publishDashboard,
        children: 'Publish "For You"',
      }}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  transition: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    width: "100%",
  },
  sidebarItem: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    gap: theme.spacing(1),
    border: `1px solid ${
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[300]
        : theme.palette.groovy.neutral[300]
    }`,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(2),
  },
}))
